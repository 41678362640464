import Header from "./components/header";
import AnimatedRoutes from "./components/animatedRoutes";

function App() {
  const personalDetails = {
    name: "Obaloluwa Olusoji",
    location: "Lagos, Nigeria",
    email: "hello@obaloluwa.com",
    availability: "Open for work",
    content:
      "I am a passionate frontend developer with a thirst for knowledge and a love for crafting highly scalable and innovative web applications. I am a collaborative team player and I enjoy working in agile teams and also very open to embracing new technologies. I enjoy gaming, watching action-adventure content, and gaining knowledge across diverse fields. I am excited to contribute my expertise in a professional enviroment and grow in the dynamic world of software development.",
  };

  return (
    <>
      <Header />
      <AnimatedRoutes personalDetails={personalDetails} />
    </>
  );
}

export default App;
