import { motion } from "framer-motion";
import SocialIcons from "../components/socialIcons";
import { useInView } from "react-intersection-observer";
import { useState, useEffect } from "react";
import resume from "../pages/about/ObaloluwaOlusojiResume.pdf";

const AboutMe = ({ content, skillSet }) => {
  const [ref, inView] = useInView({
    threshold: 0.2,
    triggerOnce: true,
  });

  const [downloading, setDownloading] = useState(false);

  useEffect(() => {
    setDownloading(false);
  }, [downloading]);

  const handleDownload = () => {
    setDownloading(true);
    const link = document.createElement("a");
    link.href = resume;
    link.download = "ObaloluwaOlusojiResume.pdf";
    link.onload = () => {
      link.remove();
      setDownloading(false);
    };
    document.body.appendChild(link);
    link.click();
  };

  return (
    <>
      <div className="aboutContainer container">
        <div className="row">
          <motion.div
            className="col-12"
            ref={ref}
            initial={{ x: "10vw", opacity: 0 }}
            animate={inView ? { x: 0, opacity: 1 } : { x: "10vw", opacity: 0 }}
            transition={{ duration: 0.4, ease: "easeInOut" }}
          >
            <div className="contentContainer">
              <h4>Nice to meet you</h4>
              <h5>
                Software developer who creates bespoke solutions and
                experiences!
              </h5>
              <div className="contentDescription">
                <p>{content}</p> {/**This is the content text */}
              </div>
              <div className="buttonContainer">
                <button
                  className="btn downloadCV"
                  onClick={handleDownload}
                  disabled={downloading}
                >
                  {downloading ? "Downloading..." : "Download Resume"}
                </button>{" "}
                <SocialIcons />
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </>
  );
};

export default AboutMe;
