import AboutMe from "../../components/aboutMe";
import Skills from "../../components/skills";
import PageHeader from "../../components/pageHeader";

const About = ({ content }) => {
  return (
    <>
      <section className="about">
        <PageHeader title="About Me" description="Get to know me!" />
        <AboutMe content={content} />
      </section>
      <section className="about">
        <PageHeader title="My skills" description="" />
        <Skills />
      </section>
    </>
  );
};

export default About;
