import skillList from ".././pages/about/skillList.json";
import SkillCard from "./skillCard";

export default function Skills() {
  const SkillData = () =>
    skillList.map((skill) => (
      <SkillCard
        key={skill.title}
        title={skill.title}
        skill1={skill.skill1}
        skill2={skill.skill2}
        skill3={skill.skill3}
        skill4={skill.skill4}
        skill5={skill.skill5}
        color={skill.color}
      />
    ));

  return (
    <section className="container contentContainer col-12 col-lg-10">
      <div className="row">
        <SkillData />
      </div>
    </section>
  );
}
