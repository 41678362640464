import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

export default function SkillCard({
  title,
  skill1,
  skill2,
  skill3,
  skill4,
  skill5,
  color,
}) {
  const [ref, inView] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  const variants = {
    hidden: { x: false ? "10vw" : "-10vw", opacity: 0 }, //do this to work that till they scroll down before the tiles show
    visible: { x: 0, opacity: 1 },
  };

  return (
    <motion.div
      ref={ref}
      className="col-sm-12 col-lg-4"
      variants={variants}
      initial="hidden"
      animate={inView ? "visible" : "hidden"}
      transition={{ duration: 0.4, ease: "easeInOut" }}
    >
      <div
        style={{ backgroundColor: color }}
        className="skillCard d-flex flex-column align-items-center justify-content-center p-5"
      >
        <h4 className="textWrap">{title}</h4>
        <h5>
          <ul>
            <li>{skill1}</li>
            <li>{skill2}</li>
            <li>{skill3}</li>
            <li>{skill4}</li>
            <li>{skill5}</li>
          </ul>
        </h5>
      </div>
    </motion.div>
  );
}
