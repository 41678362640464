import { Routes, Route, useLocation } from "react-router-dom";

import Landing from "../pages/landing/landing";
import About from "../pages/about/about";
import Projects from "../pages/projects/projects";
import Contact from "../pages/contact/contact";

const AnimatedRoutes = ({ personalDetails }) => {
  const location = useLocation();

  return (
    <Routes location={location} key={location.pathname}>
      <Route
        path="/"
        element={
          <Landing
            name={personalDetails.name}
            tagline={personalDetails.tagline}
          />
        }
      />
      <Route
        path="/about"
        element={<About content={personalDetails.content} />}
      />
      <Route path="/projects" element={<Projects />} />
      <Route
        path="/contact"
        element={
          <Contact
            name={personalDetails.name}
            location={personalDetails.location}
            email={personalDetails.email}
          />
        }
      />
    </Routes>
  );
};

export default AnimatedRoutes;
